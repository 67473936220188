export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/about": [~3],
		"/adminxyz": [~4],
		"/adminxyz/chat_gpt": [5],
		"/adminxyz/exam_boards": [~6],
		"/adminxyz/public_exam_disciplines": [~7],
		"/adminxyz/public_exam_position_roles": [~8],
		"/adminxyz/public_exams_areas": [~10],
		"/adminxyz/public_exams": [~9],
		"/adminxyz/user_requests": [~11],
		"/chat-gpt": [12],
		"/contact": [13],
		"/denied": [~21],
		"/dropdown": [22],
		"/d/cycles/[objective_id]": [~14],
		"/d/dashboard": [~15],
		"/d/disciplines/[objective_id]/[discipline_id]": [~16],
		"/d/objectives": [~17],
		"/d/study_history/[objective_id]/[discipline_id]/[topic_id]": [~18],
		"/d/topics/[objective_id]/[discipline_id]": [~19],
		"/d/user_study_types/[objective_id]/[discipline_id]": [~20],
		"/forgot": [23],
		"/habits": [~24],
		"/login": [~25],
		"/signup": [26],
		"/sitemap": [27],
		"/subscriptions": [~28],
		"/test": [29],
		"/users/[ddmmyyyy]": [~30]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';